import { makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import renderBloks from '@renderBloks'
import ContentContainer from '@system/content-container'
import PageContainer from '@system/page-container'
import ACC_THEME from '@themes'
import React, { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  navatticModuleRoot: {
    alignItems: 'center',
    borderRadius: '8px',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
  },
  navatticModuleContent: ({ gatingForm, isModalOpen }) => ({
    backgroundColor: theme.palette.background.slite,
    height: '100%',
    position: gatingForm?.length > 0 && isModalOpen ? 'absolute' : null,
    width: '100%',
    zIndex: gatingForm?.length > 0 && isModalOpen ? '0' : null,
    [theme.breakpoints.down('sm')]: {
      padding: gatingForm?.length > 0 ? '16px 8px' : null,
    },
  }),
  navatticModuleIframe: {
    border: 'none',
    height: '100%',
    minHeight: '640px',
    width: '100%',
  },
  navatticModuleModal: ({ isModalOpen }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.background.modal,
    display: isModalOpen ? 'flex' : 'none',
    justifyContent: 'center',
    left: '0',
    minHeight: '640px',
    padding: '32px 16px',
    top: '0',
    width: '100%',
    zIndex: '1',
  }),
  navatticModuleModalContent: {
    backgroundColor: theme.palette.common.white,
    maxWidth: '540px',
    width: '100%',
  },
}))

const NavatticModule = ({
  blok: { gatingForm, hasContainer, mobileUX, navatticId, padding },
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const classes = useStyles({ gatingForm, isModalOpen, padding })
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  const navatticEl = () => {
    if (handHeldDevice) {
      return renderBloks(mobileUX)
    }

    return (
      <iframe
        allowFullScreen="true"
        className={classes.navatticModuleIframe}
        mozallowfullscreen="true"
        src={`https://capture.navattic.com/${navatticId}`}
        title={'navattic'}
        webkitallowfullscreen="true"
      />
    )
  }

  return (
    <PageContainer>
      <ContentContainer parentWidth={!hasContainer}>
        <div className={classes.navatticModuleRoot}>
          {gatingForm?.length > 0 && (
            <div className={classes.navatticModuleModal}>
              <div className={classes.navatticModuleModalContent}>
                {renderBloks(gatingForm, {
                  handleNavatticModule: () => setIsModalOpen(false),
                })}
              </div>
            </div>
          )}
          <div className={classes.navatticModuleContent}>{navatticEl()}</div>
        </div>
      </ContentContainer>
    </PageContainer>
  )
}

export default NavatticModule
